/* ====== TO TOP ====== */
jQuery(document).ready(function ($) {
    var offset = 100,
        offset_opacity = 1200,
        scroll_top_duration = 700,
        $back_to_top = $('.cd-top');
    $(window).scroll(function () {
        ($(this).scrollTop() > offset) ? $back_to_top.addClass('cd-is-visible') : $back_to_top.removeClass('cd-is-visible cd-fade-out');
        if ($(this).scrollTop() > offset_opacity) {
            $back_to_top.addClass('cd-fade-out');
        }
    });
    $back_to_top.on('click', function (event) {
        event.preventDefault();
        $('body,html').animate({
            scrollTop: 0
        }, scroll_top_duration
        );
    });
});


/* ====== Sticky Nav ====== */
$(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll >= 130) {
        $(".navbar").addClass("sticky");
    } else {
        $(".navbar").removeClass("sticky");
    }
});


/* ====== COUNTER ====== */
$('.counter').counterUp({
    delay: 20,
    time: 2000
});


/* ====== Validation ====== */
jQuery(function ($) {
    $.validator.addMethod("customemail",
        function (value, element) {
            return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.(rs|com|cc|info|live|org|io|studio|shop|biz|in|hr|website|me|co\.(in|uk|rs)|org\.(rs)|edu\.(rs)|me|org|net|edu|gov|info|срб)$/.test(value);
        },
        "Email adresa nije uneta u ispravnom formatu"
    );
    $('#btn_send').click(function () {
        var $captcha = $('#recaptcha'),
            response = grecaptcha.getResponse();

        if (response.length === 0) {
            $('.msg-error').text("reCAPTCHA je obavezna");
            if (!$captcha.hasClass("error")) {
                $captcha.addClass("error");
            }
        } else {
            $('.msg-error').text('');
            $captcha.removeClass("error");
        }
    });
    // KONTAKT FORMA SRB
    $("#contact_form_srb").validate({
        rules: {
            ime_prezime: {
                required: true
            },
            email: {
                required: true,
                email: true,
                customemail: true
            },
            telefon: {
                required: true
            },
            poruka: {
                required: true
            }
        },
        messages: {
            ime_prezime: "Unesite vaše ime i prezime",
            email: {
                required: "Unesite vašu email adresu",
                email: "Email adresa nije uneta u ispravnom formatu"
            },
            telefon: "Unesite vaš broj mobilnog telefona",
            poruka: "Unesite vašu poruku"
        }
    });


    $.validator.addMethod("customemail_en",
        function (value, element) {
            return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.(rs|com|cc|info|live|org|io|studio|shop|biz|in|hr|website|me|co\.(in|uk|rs)|org\.(rs)|edu\.(rs)|me|org|net|edu|gov|info|срб)$/.test(value);
        },
        "Email address is not in correct format"
    );
    // KONTAKT FORMA ENG
    $("#contact_form_eng").validate({
        rules: {
            full_name: {
                required: true
            },
            email_eng: {
                required: true,
                email: true,
                customemail_en: true
            },
            phone: {
                required: true
            },
            message: {
                required: true
            }
        },
        messages: {
            full_name: "Please enter your full name",
            email_eng: {
                required: "Please enter your email address",
                email: "Email address is not in correct format"
            },
            phone: "Please enter your phone number",
            message: "Please enter your mesage"
        }
    });
});